import {
  createContext,
  PropsWithChildren,
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { AmbientLight } from "three";

type LightingProviderProps = PropsWithChildren & {
  color: string;
  maxIntensity: number;
  minIntensity: number;
  intensity: number;
};

export type LightingContextType = {
  ambientLight: ReturnType<typeof useRef<AmbientLight | null>>;
  toggleLight: (name: string, isOn: boolean) => void;
  addDevice: (name: string, intensity: number) => string;
};

export const LightingContext = createContext<LightingContextType | null>(null);

let lightingDevices: {
  [key: string]: number;
} = {};

const LightingProvider = ({
  children,
  maxIntensity,
  minIntensity,
  intensity,
}: LightingProviderProps) => {
  const ambientLightRef = useRef<AmbientLight>(null);
  const [curIntensity, setCurIntensity] = useState(intensity);

  const toggleLight = useCallback(
    (name: string, isOn: boolean) => {
      if (name in lightingDevices) {
        const intensityAmount = isOn
          ? lightingDevices[name]
          : -lightingDevices[name];
        setCurIntensity((prev) =>
          Math.max(Math.min(maxIntensity, prev + intensityAmount), minIntensity)
        );
      }
    },
    [maxIntensity, minIntensity]
  );

  const addDevice = useCallback((name: string, intensity: number) => {
    lightingDevices[name] = intensity;
    return name;
  }, []);

  useEffect(() => {
    return () => {
      lightingDevices = {};
    };
  }, []);

  return (
    <LightingContext.Provider
      value={{
        ambientLight: ambientLightRef,
        toggleLight,
        addDevice,
      }}
    >
      <ambientLight
        intensity={curIntensity}
        color={"#BECFA5"}
        ref={ambientLightRef}
      />

      {children}
    </LightingContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useLighting = () => {
  const context = useContext(LightingContext);
  if (!context) {
    throw new Error("useLighting must be used within a LightingProvider");
  }
  return context;
};

export default LightingProvider;
