import { Text } from "@react-three/drei";
import { Color, GroupProps, Vector3 } from "@react-three/fiber";
import Arrow, { ArrowProps } from "./Arrow";
import { FONTS } from "../assets";
import { ReactNode, useMemo } from "react";

type TextArrowProps = ArrowProps &
  GroupProps & {
    fontSize?: number;
    fontWeight?: number | string;
    fontStyle?: "italic" | "normal";
    font?: string;
    lineHeight?: number;
    letterSpacing?: number;
    textAlign?: "left" | "right" | "center" | "justify";
    children?: ReactNode;
    color?: Color;
    maxWidth?: number;
  };

const TextArrow = ({
  children,
  direction = "UP",
  font = FONTS.INDIE_FLOWER_TTF,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
  mirror,
  ...props
}: TextArrowProps) => {
  const textProps = {
    font,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    letterSpacing,
    textAlign,
  };

  const textPos: Vector3 = useMemo(() => {
    switch (direction) {
      case "UP":
        return [0.15, -0.4, 0];
      case "DOWN":
        return [-0.15, 0.4, 0];
      case "LEFT":
        return [0.8, 0.2, 0.01];
      case "RIGHT":
        return [-0.6, -0.2, 0.01];
      default:
        throw new Error(`Invalid direction: ${direction}`);
    }
  }, [direction]);

  return (
    <group {...props}>
      <Arrow direction={direction} mirror={mirror} />
      <Text position={textPos} scale={0.25} {...textProps}>
        {children}
      </Text>
    </group>
  );
};

export default TextArrow;
