import { PositionalAudio, useAnimations, useGLTF } from "@react-three/drei";
import { MODEL, MUSIC } from "../../assets";
import { PrimitiveProps } from "@react-three/fiber";
import { PositionalAudio as PositionalAudioImpl } from "three";
import { useEffect, useRef, useState } from "react";
import pointerEvents from "../../utils/pointerEvents";
import { useLighting } from "../../providers/LightingProvider";
import TextArrow from "../../Arrow/TextArrow";

type SpeakerProps = Omit<PrimitiveProps, "object"> & {};

const Speaker = ({ ...props }: SpeakerProps) => {
  const speakerModel = useGLTF(MODEL.SPEAKER_URL);
  const musicRef = useRef<PositionalAudioImpl>(null);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const animations = useAnimations(speakerModel.animations, speakerModel.scene);
  const { toggleLight, addDevice } = useLighting();
  const lightingDeviceName = addDevice("Music", 1);

  useEffect(() => {
    musicRef.current?.setVolume(0.8);
  }, []);

  useEffect(() => {
    const playAnimation = animations.actions["Play"];

    if (isMusicPlaying) {
      playAnimation?.play();
    } else {
      playAnimation?.stop();
    }

    return () => {
      playAnimation?.stop();
    };
  }, [animations.actions, isMusicPlaying]);

  return (
    <>
      <primitive
        castShadow
        object={speakerModel.scene}
        position={[2.7, -1.05, -1.3]}
        scale={0.5}
        rotation={[0, -Math.PI / 5, 0]}
        onClick={() => {
          if (isMusicPlaying) {
            musicRef.current?.stop();
            setIsMusicPlaying(false);
          } else {
            musicRef.current?.play();
            setIsMusicPlaying(true);
          }
          toggleLight(lightingDeviceName, !isMusicPlaying);
        }}
        {...props}
        {...pointerEvents}
      >
        <TextArrow
          visible={!isMusicPlaying}
          direction="LEFT"
          position={[2, 1, 0]}
          scale={1.5}
        >
          Play music
        </TextArrow>
        <PositionalAudio
          url={MUSIC.LOFI.COZY_COFFEE}
          distance={10}
          ref={musicRef}
        />
      </primitive>
    </>
  );
};

useGLTF.preload(MODEL.SPEAKER_URL);

export default Speaker;
