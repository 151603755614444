import { Euler, MeshProps, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import arrowTexturePath from "../assets/images/arrow.png";
import { useMemo } from "react";

export type ArrowProps = MeshProps & {
  direction?: "UP" | "DOWN" | "LEFT" | "RIGHT";
  mirror?: boolean;
};

const ROTATION_ANGLE_FACTOR = 0.6;

const Arrow = ({ direction = "UP", mirror = false, ...props }: ArrowProps) => {
  const arrowTexture = useLoader(TextureLoader, arrowTexturePath);

  const rotation: Euler = useMemo(() => {
    switch (direction) {
      case "UP":
        if (mirror) {
          return [0, Math.PI, Math.PI + ROTATION_ANGLE_FACTOR];
        }
        return [0, 0, ROTATION_ANGLE_FACTOR];
      case "DOWN":
        if (mirror) {
          return [0, Math.PI, 0];
        }
        return [0, 0, Math.PI + ROTATION_ANGLE_FACTOR];
      case "LEFT":
        if (mirror) {
          return [0, Math.PI, Math.PI / 2 + ROTATION_ANGLE_FACTOR];
        }
        return [0, 0, Math.PI / 2 + ROTATION_ANGLE_FACTOR];
      case "RIGHT":
        if (mirror) {
          return [0, Math.PI, -Math.PI / 2 + ROTATION_ANGLE_FACTOR];
        }
        return [0, 0, -Math.PI / 2 + ROTATION_ANGLE_FACTOR];
      default:
        throw new Error(`Invalid direction: ${direction}`);
    }
  }, [direction, mirror]);

  const scale = useMemo(() => {
    return mirror ? -1 : 1;
  }, [mirror]);

  return (
    <mesh rotation={[...rotation]} scale={scale} {...props}>
      <planeGeometry args={[0.5, 0.5]} />
      <meshBasicMaterial map={arrowTexture} transparent />
    </mesh>
  );
};

export default Arrow;
