/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 ./public/models/macbook.gltf 
*/

import { Suspense, useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { GroupProps, ThreeEvent } from "@react-three/fiber";
import { Mesh } from "three";
import { animated, easings, useSpring } from "@react-spring/three";
import pointerEvents from "../utils/pointerEvents";
import { MODEL } from "../assets";
import { useFocusDevice } from "../providers/FocusDeviceProvider";

enum LaptopScreenStatus {
  OPEN,
  CLOSE,
}

type MacbookProps = GroupProps & {
  isInteractionEnabled: boolean;
  initialStatus?: LaptopScreenStatus;
  openingAndClosingDuration?: number;
};

const ROTATION_DEGREE = {
  OPEN: [Math.PI / 2.5 + 0.06, 0, 0],
  CLOSE: [Math.PI, 0, 0],
};

export default function Macbook({
  isInteractionEnabled = true,
  initialStatus = LaptopScreenStatus.CLOSE,
  openingAndClosingDuration = 1000,
  ...props
}: MacbookProps) {
  const { nodes: originalNodes, materials } = useGLTF(MODEL.MACBOOK_URL);
  const nodes = originalNodes as {
    [name: string]: Mesh;
  };

  const [laptopScreenStatus, setLaptopScreenStatus] = useState(initialStatus);
  const [spring, api] = useSpring(() => ({
    rotation: ROTATION_DEGREE.CLOSE,
    config: {
      mass: 2,
      tension: 170,
      friction: 26,
      duration: openingAndClosingDuration,
      easing: easings.easeOutCirc,
    },
  }));

  const { setIsMacbookOpen } = useFocusDevice();

  const openCloseScreenAccordingToStatus = () => {
    switch (laptopScreenStatus) {
      case LaptopScreenStatus.OPEN:
        api.start({
          rotation: ROTATION_DEGREE.CLOSE,
          config: {
            easing: easings.easeOutCirc,
          },
        });
        setLaptopScreenStatus(LaptopScreenStatus.CLOSE);
        break;
      case LaptopScreenStatus.CLOSE:
        api.start({
          rotation: ROTATION_DEGREE.OPEN,
          config: {
            easing: easings.easeOutBack,
          },
        });
        setLaptopScreenStatus(LaptopScreenStatus.OPEN);
        break;
      default:
        api.stop();
        break;
    }
  };

  // Make sure the laptop screen follows the status
  useEffect(() => {
    if (
      laptopScreenStatus === LaptopScreenStatus.OPEN &&
      spring.rotation.get() !== ROTATION_DEGREE.OPEN
    ) {
      api.start({
        rotation: ROTATION_DEGREE.OPEN,
        config: {
          easing: easings.easeOutBack,
        },
      });
    } else if (
      laptopScreenStatus === LaptopScreenStatus.CLOSE &&
      spring.rotation.get() !== ROTATION_DEGREE.CLOSE
    ) {
      api.start({
        rotation: ROTATION_DEGREE.CLOSE,
        config: {
          easing: easings.easeOutCirc,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCloseLaptopScreen = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (!isInteractionEnabled) {
      return;
    }

    openCloseScreenAccordingToStatus();
  };

  useEffect(() => {
    setIsMacbookOpen(laptopScreenStatus === LaptopScreenStatus.OPEN);
  }, [laptopScreenStatus, setIsMacbookOpen]);

  return (
    <Suspense>
      <group {...props} dispose={null}>
        <group position={[0, 0.519, 0]} scale={0.103}>
          <mesh
            geometry={nodes.Circle001.geometry}
            material={materials["Frame.001"]}
          />
          <mesh
            geometry={nodes.Circle001_1.geometry}
            material={materials["Frame.001"]}
          />
          <mesh
            geometry={nodes.Circle001_2.geometry}
            material={materials.HeadPhoneHole}
          />
          <mesh
            geometry={nodes.Circle001_3.geometry}
            material={materials.USB_C_INSIDE}
          />
          <mesh
            geometry={nodes.Circle001_4.geometry}
            material={materials["Frame.001"]}
          />
          <mesh
            geometry={nodes.Circle001_5.geometry}
            material={materials.TouchbarBorder}
          />
          <mesh
            geometry={nodes.Circle001_6.geometry}
            material={materials.Keyboard}
          />
          <group position={[0, -0.509, 0]} scale={5.796}>
            <mesh
              geometry={nodes.Circle006.geometry}
              material={materials["Frame.001"]}
            />
            <mesh
              geometry={nodes.Circle006_1.geometry}
              material={materials.USB_C_INSIDE}
            />
          </group>
          <group position={[-11.786, -0.15, -8.301]} scale={5.796}>
            <mesh
              geometry={nodes.Circle.geometry}
              material={materials["Keyboard.001"]}
            />
            <mesh geometry={nodes.Circle_1.geometry} material={materials.Key} />
            <mesh
              geometry={nodes.Circle_2.geometry}
              material={materials.DisplayGlass}
            />
          </group>
          <mesh
            geometry={nodes.KeyboardKeyHole.geometry}
            material={materials["Keyboard.001"]}
            position={[-11.786, -0.152, -8.301]}
            scale={5.796}
          />
          <mesh
            geometry={nodes.RubberFoot.geometry}
            material={materials.DarkRubber}
            position={[-11.951, -0.751, 7.857]}
            scale={5.796}
          />
          <group position={[0.011, -0.211, -10.559]} scale={5.796}>
            <mesh
              geometry={nodes.Circle012.geometry}
              material={materials.HingeBlack}
            />
            <mesh
              geometry={nodes.Circle012_1.geometry}
              material={materials.HingeMetal}
            />
          </group>
          <group position={[-15.026, 0.031, 0.604]} scale={5.796}>
            <mesh
              geometry={nodes.Circle009.geometry}
              material={materials["Frame.001"]}
            />
            <mesh
              geometry={nodes.Circle009_1.geometry}
              material={materials.SpeakerHole}
            />
          </group>
          <group position={[12.204, 0.031, 0.604]} scale={5.796}>
            <mesh
              geometry={nodes.Circle003.geometry}
              material={materials["Frame.001"]}
            />
            <mesh
              geometry={nodes.Circle003_1.geometry}
              material={materials.SpeakerHole}
            />
          </group>
          <animated.group
            position={[0.007, -0.392, -10.7]}
            rotation={
              spring.rotation.to((x, y, z) => [x, y, z]) as unknown as [
                x: number,
                y: number,
                z: number
              ]
            }
            scale={5.796}
            onClick={openCloseLaptopScreen}
            {...pointerEvents}
          >
            <mesh
              geometry={nodes.Circle002.geometry}
              material={materials["Frame.001"]}
            />
            <mesh
              geometry={nodes.Circle002_1.geometry}
              material={materials.Screen}
            />
            <mesh
              geometry={nodes.Circle002_2.geometry}
              material={materials.ScreenGlass}
            />
            <mesh
              geometry={nodes.Circle002_3.geometry}
              material={materials.Rubber}
            />
            <mesh
              geometry={nodes.Circle002_4.geometry}
              material={materials.DisplayGlass}
            />
            <mesh
              geometry={nodes.AppleLogo000.geometry}
              material={materials["AppleLogo.004"]}
              position={[0.005, -0.111, -1.795]}
              rotation={[Math.PI, Math.PI, -Math.PI]}
              scale={0.579}
            />
          </animated.group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload(MODEL.MACBOOK_URL);
