const PREFIX = {
  MODEL: "models",
  IMAGES: "images",
  SOUNDS: "sfx",
  FONTS: "fonts",
  MUSIC: "musics",
};

export const MODEL = {
  MACBOOK_URL: `${PREFIX.MODEL}/macbook.gltf`,
  IPHONE_URL: `${PREFIX.MODEL}/iphone.gltf`,
  SPEAKER_URL: `${PREFIX.MODEL}/speaker.glb`,
};

export const SOUNDS = {
  VIBRATE: `${PREFIX.SOUNDS}/vibrate.m4a`,
  MAC_BOOT: `${PREFIX.SOUNDS}/boot-sound.m4a`,
  KEYBOARD_CLICK: `${PREFIX.SOUNDS}/keyboard-click.wav`,
  LIGHT_SWITCH: `${PREFIX.SOUNDS}/light-switch.m4a`,
};

export const FONTS = {
  INDIE_FLOWER_TTF: `${PREFIX.FONTS}/IndieFlower-Regular.ttf`,
  BEBAS_JSON: `${PREFIX.FONTS}/Bebas.json`,
  SILKSCREEN_JSON: `${PREFIX.FONTS}/Silkscreen_Regular.json`,
  NDOT_47_OTF: `${PREFIX.FONTS}/ndot-47/ndot-47.otf`,
};

export const MUSIC = {
  LOFI: {
    COZY_COFFEE: `${PREFIX.MUSIC}/lofi/cozy-coffee.mp3`,
  },
};